import classNames from 'classnames';
import { forwardRef } from 'react';
import type React from 'react';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-alt'
  | 'secondary-light'
  | 'assistant'
  | 'assistant-alt'
  | 'cta'
  | 'link'
  | 'minimal'
  | 'label'
  | 'minimal-alt'
  | 'minimal-light';

export type ButtonSize =
  | 'xs'
  | 'xs-square'
  | 'sm'
  | 'sm-square'
  | 'sm-circle'
  | 'md'
  | 'md-square'
  | 'md-circle'
  | 'lg'
  | 'xl';

type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  href?: string;
  className?: string;
  isSubmit?: boolean;
};

const BOOTSTRAP_PROPS = ['bsClass', 'bsSize', 'bsStyle', 'bsRole'];

const Button = forwardRef<HTMLButtonElement, ButtonProps & React.ComponentProps<'button' | 'a'>>(
  (
    {
      children,
      variant = 'primary',
      size = 'md',
      href = null,
      className = null,
      isSubmit = false,
      ...props
    },
    ref,
  ): React.JSX.Element => {
    // Variables
    const renderChildren = variant !== 'assistant-alt';

    // Functions
    function getClassName() {
      return classNames(`sc-btn sc-btn--${variant} sc-btn--${size}`, className);
    }

    function getElementProps() {
      const elementProps = {};

      Object.entries(props).forEach(([key, value]) => {
        if (!BOOTSTRAP_PROPS.includes(key)) {
          elementProps[key] = value;
        }
      });

      if (variant === 'assistant-alt') {
        elementProps['data-text'] = children;
      }

      return elementProps;
    }

    // Render
    if (href) {
      return (
        <a href={href} className={getClassName()} {...getElementProps()}>
          {renderChildren && children}
        </a>
      );
    }

    return (
      <button
        ref={ref}
        type={isSubmit ? 'submit' : 'button'}
        className={getClassName()}
        {...getElementProps()}
      >
        {renderChildren && children}
      </button>
    );
  },
);

export default Button;
